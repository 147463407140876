<template>
  <div class="row">
    <div class="col-lg-8 col-md-12">
      <div>
        <div
          v-if="live !== null"
        >
          <div>
            <b-embed
              type="iframe"
              aspect="16by9"
              :src="'https://www.youtube.com/embed/'+live.youtube"
              allowfullscreen
            />
          </div>
        </div>
      </div>
    </div>
    <div class="col-lg-4 col-md-12">
      <div style="margin-bottom: 10px">
        <b-button
          v-if="live.deezer !== 'null'"
          :href="`https://deezer.page.link/${live.deezer}/`"
          variant="outline-primary"
          class="buttonSpace"
        >
          <span>
            <img
              src="@/assets/images/podcasts/deezer.svg"
              width="30px"
            ><br>Deezer
          </span>
        </b-button>
        <b-button
          v-if="live.doogle_podcast !== 'null'"
          :href="`https://podcasts.google.com/feed/${live.doogle_podcast}/`"
          variant="outline-primary"
          class="buttonSpace"
        >
          <span>
            <img
              src="@/assets/images/podcasts/google.svg"
              width="30px"
            ><br>Google podcast
          </span>
        </b-button>
        <b-button
          v-if="live.spotify !== 'null'"
          :href="`https://open.spotify.com/episode/${live.spotify}/`"
          variant="outline-primary"
          class="buttonSpace"
        >
          <span>
            <img
              src="@/assets/images/podcasts/spotify.svg"
              width="30px"
            ><br>Spotify
          </span>
        </b-button>
        <b-button
          v-if="live.apple_podcast !== 'null'"
          :href="`https://podcasts.apple.com/br/podcast/podcast-sbg-papers/${live.apple_podcast}/`"
          variant="outline-primary"
          class="buttonSpace"
        >
          <span>
            <img
              src="@/assets/images/podcasts/applemusic.svg"
              width="30px"
            ><br>Apple podcast
          </span>
        </b-button>
        <b-button
          v-if="live.youtube !== 'null'"
          :href="`https://www.youtube.com/watch?v=${live.youtube}/`"
          variant="outline-primary"
          class="buttonSpace"
        >
          <span>
            <img
              src="@/assets/images/podcasts/youtube.svg"
              width="30px"
            ><br>Youtube
          </span>
        </b-button>
        <b-button
          v-if="live.soundcloud !== 'null'"
          :href="`https://soundcloud.com/sbglaucoma/${live.soundcloud}/`"
          variant="outline-primary"
          class="buttonSpace"
        >
          <span>
            <img
              src="@/assets/images/podcasts/soundcloud.svg"
              width="30px"
            ><br>Soundcloud
          </span>
        </b-button>
      </div>
      <br>
      <chat
        :live-id="live.id"
        :user-id="sessionInfo.user.id"
        style="margin:0px;z-index: 999999;"
      />
    </div>
  </div>
</template>

<script>
import LivesServices from './LivesServices'
import chat from '@/components/chat/ChatLive.vue'

export default {
  components: {
    chat
  },
  data() {
    return {
      youtube_base: 'https://www.youtube.com/embed/',
      vimeo_base: 'https://iframe.videodelivery.net/',
      // eslint-disable-next-line radix
      slug: parseInt(this.$route.params.slug),
      liveSpeaker: {},
      live: {},
      loading: false,
      options: {
        autoplay: true,
        fullscreen: {
          enabled: true,
          fallback: true,
          iosNative: true,
          container: null,
        },
      },
    }
  },
  computed: {
    sessionInfo() {
      return this.$store.state.auth.sessionInfo
    },
  },
  mounted() {
    LivesServices().getLive({ slug: this.$route.params.slug }).then(api => {
      this.live = api.data.response.podcast
      console.log('teste')
    })

    setInterval(() => {
      LivesServices().getLive({ slug: this.$route.params.slug }).then(api => {
        this.live = api.data.response.podcast
      })
    }, 30000)

    LivesServices().getLiveSpeakerByLive({
      live_id: this.live.id,
    }).then(api => {
      this.liveSpeaker = api.data.response.liveSpeaker
    })

    LivesServices().liveMonitoring({
      live_id: this.live.id
    })
  }
}
</script>

<style>
.buttonSpace{
  margin: 2px;
}
</style>
