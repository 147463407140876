import axios from '@axios'

export default function livesServices() {
  const getLives = async (queryParams) => {
    const api = await axios.get('/podcasts', queryParams)
    return api
  }

  const getLive = async (queryParams) => {
    const api = await axios.get('/podcast', {
      params: queryParams,
    })
    return api
  }

  const getLiveSpeakerBySpeaker = async (queryParams) => {
    const api = await axios.get('/live/speaker/byspeaker', {
      params: queryParams,
    })
    return api
  }

  const getLiveSpeakerByLive = async (queryParams) => {
    const api = await axios.get('/live/speaker/bylive', {
      params: queryParams,
    })

    return api
  }

  const liveMonitoring = async (queryParams) => {
    const api = await axios.post('/live/monitoring', queryParams)
    return api
  }

  return {
    getLives,
    getLive,
    getLiveSpeakerBySpeaker,
    getLiveSpeakerByLive,
    liveMonitoring,
  }
}
